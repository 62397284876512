import Cookie from 'js-cookie';

export function addOrderDataToSelectedElements(){
	addClassToCtas();
	addOrderDataToElement('cta-button-tracking-class');
	addOrderDataToElement('js-video-popup');
}

function addOrderDataToElement(classId){
	var elements = $("."+classId);
	var order = 1;
	elements.each(function(index,value){
		$(value).attr('data-item-order',order);
		order = order + 1;
	});
}

function addClassToCtas(){
	var allelements = $("a:contains(Schedule Free Consultation),a:contains(Schedule A Free Consultation)");
	allelements.each(function(index,value){
		if(!$(value).closest("#nav-sticky-snav").length && !$(value).closest("#header").length){
			$(value).toggleClass('cta-button-tracking-class',true);
		}

	});
}

export function addHighlightToCalDays(days){
	for(let i = 1; i < days; i++){
		if($(".currentMonth")[i].className.includes('CalActive')){
			$(".currentMonth")[i].style.backgroundColor="rgba(8, 145, 107, .2)";
			if($(".currentMonth")[i].className.includes("HighlightedDay") == false){
				$(".currentMonth")[i].className += " HighlightedDay";
			}
		}
	}
}


export function addControlDaysForComparison(days){
	for(let i = 1; i < days; i++){
		if($(".currentMonth")[i].className.includes('CalActive')){
			if($(".currentMonth")[i].className.includes("ComparativeClickClass") == false){
				$(".currentMonth")[i].className += " ComparativeClickClass";
			}
		}
	}
}

export function iquantiAbTestC3(){
	var testCookie = Cookie.get("iquantiTestC3");
	if(testCookie){
		iquantiAbTestContentController("c3v1","c3contentTestControl");
	}else{
		iquantiAbTestContentController("c3v0","c3contentTestControl");
	}

}

export function iquantiAbTestC1(){
	var testCookie = Cookie.get("iquantiTestC1");
	if(testCookie){
		iquantiAbTestContentController("c1v1","c1contentTestControl");
	}else{
		iquantiAbTestContentController("c1v0","c1contentTestControl");
	}

}

export function showCostsTest2024(){
	var testCookie = Cookie.get("showingCostsTest");
	if(testCookie){
		iquantiAbTestContentController("showingCostsTestv1","showingCostsTestController");
	}else{
		iquantiAbTestContentController("showingCostsTestv0","showingCostsTestController");
	}
}

export function formAbTestErrors(){
	if(window.location.pathname.includes("schedule-consultation")){
		$(".gfield_description").remove();
		$(".gfield_label").eq(0).append(' *<div class="gfield_description errorFormTest" id="gfield_description_19_9" >Select all that apply</div>');
		$(".gfield_label").eq(1).append(' *<div class="gfield_description errorFormTest" id="gfield_description_19_9" >Select all that apply</div>');
		$(".gfield_label").eq(3).append(' *<div class="gfield_description errorFormTest" id="gfield_description_19_9" >Select up to 3 options</div>');
		$("#field_19_37").addClass("accessibility_outline");
	}
}

export function iquantiAbTestI6(){
	var testCookie = Cookie.get("iquantiTestI6");

	if(testCookie){
		iquantiAbTestContentController("i6v1","i6contentTestControl");
    $(".page-template").addClass("v1-i6");
	}else{
		iquantiAbTestContentController("i6v0","i6contentTestControl");
	}
}

export function iquantiAbTestC6(){
	var testCookie = Cookie.get("iquantiTestC6");

	if(testCookie){
		iquantiAbTestContentController("c6v1","c6contentTestControl");
    $(".page-template").addClass("v1-c6");
     //logic for moving some of the content around
     $(".video-header > p").remove();
     $(".header-overlay-text > br").remove();
	}else{
		iquantiAbTestContentController("c6v0","c6contentTestControl");
	}
}

export function highIntentCostTest(){
	var testCookie = Cookie.get("highIntentCostTest");
  //acadia landing pages a/b test initialization for header/footer cost-of-dental
	if(testCookie){
    $(".background-container").addClass('c7v0 c7contentTestControl');
    $(".page-template").addClass("v1-c7");
    $(".high-intent-pre-form #input_33_38").change(function() {$(".high-intent-pre-form #input_33_38").css("color","black");});
    iquantiAbTestContentController("c7v1","c7contentTestControl");
    $("#mobile-form").remove();
	}else{
		iquantiAbTestContentController("c7v0","c7contentTestControl");
	}
}

function iquantiAbTestContentController(version,controllerClass){
	$("."+version).removeClass(controllerClass);
	$("."+version).show();
	$("."+controllerClass).hide();
}

export function whatToExpectTest(){
	// var testCookie = Cookie.get("whatToExpectTest");
	//acadia landing pages a/b test initialization for header/footer cost-of-dental

  $('.cta').hide();
  iquantiAbTestContentController("whatToExpectTestV1","whatToExpectTestControl");
  $(".cta-button-tracking-class").click(function(){
    window['optimizely'].push({
      type: "event",
      eventName: "reviewsBottomCta",
    });
  });

  $(".cta-clickthrough-tracker").click(function(){
    window['optimizely'].push({
      type: "event",
      eventName: "moreInfoCallToAction",
    });
  });

}

// JP - WD-763 JANUARY PROMOTION TEST
export function costPromotionJanuary2024Test(){
	var testCookie = Cookie.get("costPromotionJanuary2024TestV1");
	//acadia landing pages a/b test initialization for header/footer cost-of-dental
	if(testCookie){
		iquantiAbTestContentController("costPromotionJanuary2024TestV1","costPromotionJanuary2024TestControl");
    $(".costPromotionJanuary2024TestV0").hide()
		// $(".cta-button-tracking-class").click(function(){
		// 	window['optimizely'].push({
		// 		type: "event",
		// 		eventName: "preFormNextClick",
		// 	});
		// });

		// $(".cta-clickthrough-tracker").click(function(){
		// 	window['optimizely'].push({
		// 		type: "event",
		// 		eventName: "moreInfoCallToAction",
		// 	});
		// });
	}else{
		iquantiAbTestContentController("costPromotionJanuary2024TestV0","costPromotionJanuary2024TestControl");
	}
}

export function reviewsPageABTest(){
	if(window.location.pathname == '/dental-implants-reviews-by-patients/' || window.location.pathname == '/dental-implants-reviews-by-patients/' ){
		var reviewsCookie = Cookie.get("reviewsAbTest");
		if(reviewsCookie){
			$(".reviewsAbTestv0").hide();
			$(".bottom-cta").hide();
			$(".learn-more-floating-button").click(function(){
				window['optimizely'].push({
					type: "event",
					eventName: "reviewsLearnMoreCta",
				});
			});
			$(".reviews-cta .cta-button").click(function(){
				window['optimizely'].push({
					type: "event",
					eventName: "reviewsBottomCta",
				});
			});
		}else{
			$(".reviewsAbTestv1").attr("style","display:none!important");
			$(".civ-button").click(function(){
				window['optimizely'].push({
					type: "event",
					eventName: "reviewsLearnMoreCta",
				});
			});
			$(".cta-button-tracking-class").click(function(){
				window['optimizely'].push({
					type: "event",
					eventName: "reviewsBottomCta",
				});
			});
		}
	}
}
export function progressBar(step){
	var newThemeWithProgressBar = Cookie.get("newThemeWithProgressBar");
	if(newThemeWithProgressBar){
		$(".breadcrumb").hide();
		var svghtml = "";
		if(step == 1){
			$(".form-page-instructions h4").html("Describe Your Dental Condition");
			$(".form-page-instructions").append("<span>Your answers will help determine if you're a candidate for dental implants.</span>");

			svghtml = '<svg class="formProgressBar" width="244" height="19" viewBox="0 0 244 19" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="8.99095" y1="10" x2="240.991" y2="8.95025" stroke="white" stroke-width="4"/><circle cx="234.5" cy="9.5" r="9.5" fill="white"/><circle cx="159.5" cy="9.5" r="9.5" fill="white"/><circle cx="84.5" cy="9.5" r="9.5" fill="white"/><circle cx="9.5" cy="9.5" r="9.5" fill="#123057"/></svg>';
			$(".form-page-instructions").prepend(svghtml);
		}else if(step == 2){
			$(".form-page-instructions h4").html("Find Your Center");
			$(".form-page-instructions").append("<span>Providing your address allows us to locate nearby centers.</span>");

			svghtml = '<svg class="formProgressBar" width="244" height="19" viewBox="0 0 244 19" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="8.99095" y1="10" x2="240.991" y2="8.95025" stroke="white" stroke-width="4"/><line x1="8.97369" y1="10.0002" x2="84.9737" y2="9.00017" stroke="#123057" stroke-width="4"/><circle cx="234.5" cy="9.5" r="9.5" fill="white"/><circle cx="159.5" cy="9.5" r="9.5" fill="white"/><circle cx="84.5" cy="9.5" r="9.5" fill="#123057"/><circle cx="9.5" cy="9.5" r="9.5" fill="#123057"/></svg>';
			$(".form-page-instructions").prepend(svghtml);
		}else if(step == 3){
			svghtml = '<svg class="formProgressBar" width="244" height="19" viewBox="0 0 244 19" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="8.99095" y1="10" x2="240.991" y2="8.95025" stroke="white" stroke-width="4"/><line x1="9" y1="10" x2="160" y2="10" stroke="#123057" stroke-width="4"/><circle cx="234.5" cy="9.5" r="9.5" fill="white"/><circle cx="159.5" cy="9.5" r="9.5" fill="#123057"/><circle cx="84.5" cy="9.5" r="9.5" fill="#123057"/><circle cx="9.5" cy="9.5" r="9.5" fill="#123057"/></svg></div>';
			$(".formProgressBarContainer").html(svghtml);
		}else if(step == 4){
			svghtml = '<svg class="formProgressBar" width="244" height="19" viewBox="0 0 244 19" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="8.99115" y1="10" x2="229.991" y2="9.02214" stroke="#123057" stroke-width="4"/><circle cx="234.5" cy="9.5" r="9.5" fill="#123057"/><circle cx="159.5" cy="9.5" r="9.5" fill="#123057"/><circle cx="84.5" cy="9.5" r="9.5" fill="#123057"/><circle cx="9.5" cy="9.5" r="9.5" fill="#123057"/></svg>';
			$(".formProgressBarContainer").html(svghtml);
		}
	}
}


export function spanishLanguageTest(){
	if(window.location.pathname == '/schedule-consultation' || window.location.pathname.indexOf('schedule-consultation') > -1 ){
		var spanishLanguageTest = Cookie.get("spanishLanguageTest");
		if(!spanishLanguageTest){
			$(".field-language").hide();
		}
	}
}

export function removeThirdQuestion(){
  if(window.location.pathname == '/schedule-consultation' || window.location.pathname.indexOf('schedule-consultation') > -1 || window.location.pathname.indexOf('/schedule-now') > -1){
    global.removeThirdQuestion = true;
    $(".field-questions").eq(3).hide();
    $(".field-questions").eq(2).remove();
  }
}
